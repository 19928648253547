<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row margin-bottom-20">
                <div class="col-xs-12 col-md-5">
                    <h1 class="h2 margin-0">Procedure</h1>
                </div>
            </div>
            <div class="table-responsive" v-if="projectResearch.length > 0">
                <ResearchProjectTable :research-projects="projectResearch"></ResearchProjectTable>
            </div>
            <div v-else>
                <span class="text-muted">Non sono presenti procedure</span>
            </div>
            <div class="text-center">
                <Pagination
                        v-show="numberPagesResearchProject > 1"
                        :page-count="numberPagesResearchProject"
                        :click-handler="paginateCallback" />
            </div>
        </div>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import {Utils} from "../common/utils";
    import ResearchProjectTable from "../components/project_research/ResearchProjectTable";
    import Pagination from "../components/common/Pagination";
    import {LIMIT_RECORDS_API} from "../common/constants";
    import {getResearchProjects} from "../api";

    export default {
        name: "ResearchProject",
        components: { ResearchProjectTable, ContainerWithMenu, Pagination},
        data: function() {
            return {
                projectResearch: [],
                countResearchProject: 0,
                limitResearchProject: LIMIT_RECORDS_API,
                currentPageResearchProject: 1,
                loading: false,
                newResearchProjectModalVisible: false
            };
        },
        mounted() {
            this.getResearchProjects()
        },
        computed: {
            numberPagesResearchProject: function () {
                return Utils.getNumberPageByCountLimit(this.countResearchProject, this.limitResearchProject);
            }
        },
        methods: {
            paginateCallback(pageNum) {
                this.getResearchProjects(pageNum)
            },
            async getResearchProjects(page = 1) {
                this.loading = true;
                try {
                    let projectResearchResponse = await getResearchProjects(page);
                    this.countResearchProject = projectResearchResponse.count;
                    this.projectResearch = projectResearchResponse.results;

                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
        }
    };
</script>
