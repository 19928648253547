<template>
    <table class="table table-hover">
        <thead>
        <tr>
            <th>ID</th>
            <th>Nome</th>
            <th></th>
        </tr>
        </thead>
        <tbody id="researchProject-list">
        <ResearchProjectRow
                :researchProject="researchProject"
                v-for="researchProject in researchProjects"
                v-bind:key="researchProject.id"
        ></ResearchProjectRow>
        </tbody>
    </table>
</template>

<script>
    import ResearchProjectRow from "./ResearchProjectRow";
    export default {
        name: "ResearchProjectTable",
        components: { ResearchProjectRow },
        props: {
            researchProjects: {
                type: Array,
                required: true
            }
        }
    };


</script>
