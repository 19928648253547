<template>
    <tr class="clickable-row">
        <td><a href="" @click.prevent.stop >{{researchProject.id}}</a></td>
        <td><a href="" @click.prevent.stop>{{researchProject.name}}</a></td>
        <td class="text-right">
            <router-link  class="btn btn-default" :to="{ name: 'research_project_detail', params: { research_project_id: researchProject.id }}">
                <i class="las la-eye"></i>Mostra
            </router-link>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "ResearchProjectRow",
        props: {
            researchProject: {
                type: Object,
                require: true
            }
        }
    };
</script>
